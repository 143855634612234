import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "intro"
    }}>{`Intro`}</h1>
    <h2 {...{
      "id": "service-announcement"
    }}>{`Service announcement:`}</h2>
    <p>{`We are announcing end of life for the seating services API. This service will be deprecated as soon as all consumers of the service have migrated to the seating manager API. We plan for migrating all consumers during 2024 and we will contact each consumer to plan the migration together.`}</p>
    <p>{`We have migrated all train departures with seat reservations from Plads90 to new services, called Entur Plass. Next work in line is to replace the seating services API with seating manager API.`}</p>
    <p>{`If you experience anything unexpected with seat reservations, please let us know. To be continued!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      